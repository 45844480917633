import Config from '@/config';
import storage from "@/utils/storage";

const actions = {
    items(store, params) {
        return store.dispatch('api', {
            ...params, action: 'workspace-items',
        }, {root: true});
    },
    create(store, params) {
        return store.dispatch('api', {
            ...params, action: 'workspace-create',
        }, {root: true});
    },
    update(store, params) {
        return store.dispatch('api', {
            ...params, action: 'workspace-update',
        }, {root: true});
    },
    del(store, params) {
        return store.dispatch('api', {
            ...params, action: 'workspace-delete',
        }, {root: true});
    },
    select(store, params) {
        //console.log("SETTING WORKSPACE", params);
        if ( params?.workspaceAuth ) storage.set(Config.STORAGE_WORKSPACE_AUTH, params.workspaceAuth);
        else storage.clear(Config.STORAGE_WORKSPACE_AUTH);
        //console.log("SETTING WORKSPACE", storage.getString(Config.STORAGE_WORKSPACE_AUTH));
    },
};

export default actions;

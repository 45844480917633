import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import vueFilters from './utils/vueFilters';
import './plugins/ws';
import './styles/index.scss';
import './plugins/svgicon';
import './plugins/lordicon';
import './default-tags';

//localStorage.log = 'true';

Vue.use(vueFilters);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

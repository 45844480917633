import Config from '@/config';
import Vue from "vue";
import {ws} from "../api";
import {end} from "@/utils/string";

const actions = {

    hello(store, options) {
        return ws(Config.WS_ACTION_HELLO, {params: {mutation: null}});
    },

    /**
     * Fetches group tasks.
     * @param store
     * @param params
     * @returns {*}
     */
    tasks(store, params) {
        return store.dispatch('get', {
            action: 'user/tasks',
            params
        }, {root: true});
    },

    /**
     * Sorts topic in a group.
     * @param store
     * @param params
     * @returns {*}
     */
    taskSort(store, params) {
        console.log("ACTION user/taskSort", params);

        // local change
        store.commit("userTaskSort", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'user/taskSort',
            params
        }, {root: true});
    },

    /**
     * GETs current session.
     * @param store
     * @param params
     * @returns {*}
     */
    session(store, params) {
        // skip if session already set
        if ( store.rootState.session.user ) return;

        //console.log("ACTION user/session", params);

        // local change
        //store.commit("userSession", params, {root: true});

        // remote change
        return store.dispatch('get', {
            action: 'user/session',
            params: {
                key: "session",
                ...params,
            }
        }, {root: true});
    },

    assigneeMenu(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => {
                return resolve(res);
            };

            store.commit('uiItemMenu', {
                menu: store.rootState.ui.assigneeMenu,
                params
            }, {root: true});
        });
    },

    dialogUserEdit(store, params) {
        if (!params?.item?.auth) return;

        else return new Promise((resolve, reject) => {
            //console.log("dialogAssigneeEdit", params);
            store.rootState.ui.dialogUserEdit.item = params.item;
            //store.rootState.ui.dialogAssigneeEdit.title = "Укажите дедлайн";
            //store.rootState.ui.dialogCalendar.prop = "deadline";
            //store.rootState.ui.dialogCalendar.minDate = new Date();
            store.rootState.ui.dialogUserEdit.callback = (res) => {
                store.dispatch("user/user", {
                    userAuth: params.item.auth,
                    params: res
                }, {root: true});
                return resolve(res);
            };
            store.rootState.ui.dialogUserEdit.isVisible = true;
        });
    },

    /*companionMenu(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => {
                return resolve(res);
            };

            store.commit('uiItemMenu', {
                menu: store.rootState.ui.assigneeMenu,
                params
            }, {root: true});
        });
    },*/


    // TODO REVIEW ---------------

    sort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-task-sort',
        }, {root: true});
    },

    update(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-update',
        }, {root: true});
    },

    user(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-user',
        }, {root: true});
    },

    group(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group',
        }, {root: true});
    },

    groupSort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-sort',
        }, {root: true});
    },

    groupLeave(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-leave',
        }, {root: true});
    },



    refresh(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-refresh',
        }, {root: true});
    },
};

export default actions;

import striptags from "striptags";
import {mysql2Date} from "@/utils/date";

/**
 * Sort items *at place* by auth list.
 * @param items
 * @param topicSort is a list of auths in needed order
 * @returns {*}
 */
export const sortByAuth = (items, authSort) => {
    if ( !items ) return;
    if ( !authSort ) return;
    const indexes = {};
    const indexOfAuth = (auth) => {
        let index = -1;
        if (indexes[auth]) index = indexes[auth];
        else if (Array.isArray(authSort)) index = indexes[auth] = authSort.findIndex(el => el === auth);
        return index;
    }
    items.sort((a, b) => {
        const aIndex = indexOfAuth(a.auth||a.id);
        //console.log("A. "+a.auth+": "+aIndex);
        const bIndex = indexOfAuth(b.auth||b.id);
        //console.log("B. "+b.auth+": "+bIndex);
        return aIndex - bIndex;
    });
};
import Vue from "vue";
import axios from "axios";
import config from '@/config';
import store from '@/store';
import {mergeObjects} from '@/utils/utils';
import 'axios-progress-bar/dist/nprogress.css';
import {loadProgressBar} from 'axios-progress-bar';
import storage from "@/utils/storage";
import Config from "@/config";
import {obtainClientAuth, obtainWorkspaceAuth} from "@/utils/auth";

const axiosWithProgress = axios.create();
const axiosWithoutProgress = axios.create();
loadProgressBar({showSpinner: false}, axiosWithProgress);

export function api(cmd, options) {
    // по умолчанию - GET-запрос
    options.method = options.method || 'GET';

    console.log('API ' + options.method + ' request \"' + cmd + '\": ', options);

    // с прогрессбаром или нет?
    let axios = null;
    if (options.hidden) axios = axiosWithoutProgress;
    else axios = axiosWithProgress;

    // добавляем токен авторизации
    //let authToken = Cookie.get(config.STORAGE_AUTH_TOKEN);
    //if ( !authToken )
    //let authToken = localStorage.getItem(config.STORAGE_AUTH_TOKEN);
    //console.log("AUTH taken from cookie/storage: ",authToken);
    //axios.defaults.headers.common['Authorization'] = authToken;
    // else axios.defaults.headers.common['Authorization'] = '';

    let params = null, data = null;
    if (options.method === "GET") {
        // конкатинируем GET params & query:
        params = options.params || {};
        let query = options.query || {};
        params = mergeObjects(params, query);
    } else data = options.params;

    // определяем URL:
    let url = '';
    if (cmd.match(/^\/.+/) || cmd.match(/^http:.+/)) {
        // кастомный URL
        url = cmd;
        console.log("Requesting URL: " + url);
    } else {
        // По умолчанию обращаемся к API
        url = (config.API_SERVER_URI ? config.API_SERVER_URI : '') + '/api/' + cmd + '/';
    }

    // установка Accept
    let accepts = ["application/json"];
    if (config.WITH_WEBP) accepts.push("image/webp");
    axios.defaults.headers.common['Accept'] = accepts.join(", ");

    // кастомные заголовки
    let headers = {};
    //let headers = {'Cache-Control': 'max-age=300'};
    if (typeof options.headers !== 'undefined' && Object.keys(options.headers).length > 0) {
        headers = mergeObjects(headers, options.headers);
    }

    store.state.isLoading = true;

    // погнали
    return axios({
        method: options.method,
        url,
        params,
        headers,
        data
    })
        .then((response) => {
            console.log('API Response: ', response);

            if (!response.data && options.method === 'POST') return;
            else if (!response.data) throw 'No data in response object';
            // if(response.data.error) return router.push(<any>{name: 'Error'});

            if (response.data?.error) {
                // API standard error
                console.warn('API error: ', response.data.error);
                if (response.data.code) throw {error: response.data.error, code: response.data.code};
                else throw response.data.error;
            }
            /*if (!response.data?.result) {
                console.warn('No result in response data object');
                throw 'No result in response data object';
            }*/

            return response.data.result;
        })
        .catch((error) => {
            // TODO: communication error handling

            if (error?.response?.data?.code) {
                console.warn('API exception: ', error.response.data.error || error.response.data.code);
                throw {error: error.response.data.error, code: error.response.data.code};
            } else console.error('API fatal error: ', error);

            return response;
        })
        .finally(() => {
            store.state.isLoading = false;
        });
}

let wsRequestId = 1;

export function ws(cmd, options) {
    options = options || {};

    // конкатинируем params, query, data:
    let params = {};
    params['params'] = options.params || {};
    if (options.subscribe) params['subscribe'] = options.subscribe;
    if (options.method === "GET") {
        let query = options.query || {};
        params['params'] = mergeObjects(params['params'], query);
    } else {
        let data = options.data || {};
        params['params'] = mergeObjects(params['params'], data);
    }

    //console.log("WS request params", options);

    // по умолчанию - GET-запрос
    params['method'] = options.method || 'GET';
    // определяем действие:
    params['action'] = cmd;
    // id, если есть:
    params['id'] = wsRequestId++;

    // добавляем токен авторизации
    let authToken = obtainClientAuth();//storage.getString(Config.STORAGE_CLIENT_ID_TOKEN);
    //let authToken = localStorage.getItem(config.STORAGE_CLIENT_ID_TOKEN);
    if (authToken) params['auth'] = authToken;
    //console.log("API generated clientId", authToken);
    let workspaceAuth = obtainWorkspaceAuth();//storage.getString(Config.STORAGE_CLIENT_ID_TOKEN);
    if (workspaceAuth) params['workspaceAuth'] = workspaceAuth;

    // погнали
    return new Promise((resolve, reject) => {
        // register request
        //options['id'] = wsRequestId++;
        let request = {
            //options,
            id: params['id'],
            response: null,
            resolve,
            reject,
        };
        const wsRequests = store.state.wsRequests;
        wsRequests.push(request);

        let send = () => {
            console.log('WS API request ' + params['action'] + '[' + params['method'] + ']:', params['params'], authToken);
            //store.state.isLoading = true;

            //  отправляем запрос
            //Vue.prototype.$socket.send('some data')
            Vue.prototype.$socket.sendObj(params);
            //resolve();

            // теперь ждем ответ - мониторим wsRequests
            let time = new Date().valueOf();
            let interval = setInterval(() => {
                //console.log("checking response", request.response);
                if (request.response) {
                    clearInterval(interval);

                    /*setTimeout(()=>{
                        store.state.isLoading = false;
                    },50);*/

                    if (request.response.error) {
                        // API standard error
                        //console.warn('WS API error: ', request.response.error);
                        reject(request.response.error);
                        return;
                    }
                    /*if (!request.response.result) {
                        reject('No result in response data object');
                    }*/

                    //console.log('WS API response ' + params['action'] + '[' + params['method'] + ']:', request.response.result);
                    resolve(request.response);
                }
                if (new Date().valueOf() >= time + config.WS_RESPONSE_TIMEOUT_MS) {
                    clearInterval(interval);
                    reject('WS response timeout error');
                }
            }, config.WS_RESPONSE_CHECK_EVERY_MS);
        };

        //console.log("WS connected: "+store.state.isWSConnected);
        if (store.state.isWSConnected) send();
        else {
            // если ws еще не готов - ждем
            let wsWaiter = () => {
                return new Promise((resolve, reject) => {
                    let time = new Date().valueOf();
                    //console.log("Waiting for WS connection...");
                    let interval = setInterval(() => {
                        if (store.state.isWSConnected) {
                            clearInterval(interval);
                            resolve();
                        }
                        if (new Date().valueOf() >= time + config.WS_CONNECT_TIMEOUT_MS) {
                            clearInterval(interval);
                            reject('WS connect timeout error');
                        }
                        //console.log("Waiting for WS connection...");
                    }, config.WS_CONNECT_CHECK_EVERY_MS);
                });
            };
            wsWaiter().then(() => {
                send();
            }).catch((err) => {
                console.warn("Could not send WS request as WS is not connected: ", err);
            });
        }
    });

}

export function mutationFromAction(action) {
    return action.replace(/([\/-](\w))/, function (v, p1, p2) {
        return p2.toUpperCase();
    });
}

export default api;

import Vue from 'vue';
import Anim from '@/components/elements/anim';
import GroupTopicsDraggable from "@/components/group/group-topics-draggable";
import DialogDelete from "@/components/dialogs/dialog-delete";
import Loader from "@/components/elements/loader";
import Avatar from "@/components/elements/avatar";

// default tags
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
Vue.component('anim', Anim);
Vue.component('group-topics-draggable', GroupTopicsDraggable);
Vue.component('dialog-delete', DialogDelete);
Vue.component('loader', Loader);
Vue.component('avatar', Avatar);

import Config from '@/config';
import Vue from "vue";

const actions = {
    sort(store, params) {
        const items = params?.items;
        if ( !items?.length ) return;

        /*console.log("isRouteMy", store.rootGetters.isRouteMy);
        console.log("isRouteAssignee", store.rootGetters.isRouteAssignee);
        console.log("params", params);
        return;*/

        if ( store.rootGetters.isRouteMy || store.rootGetters.isRouteAssignee ) return store.dispatch('user/sort', {
            userAuth: params.userAuth,
            ids: items.map(el => el.id)
        }, {root: true});
        else return store.dispatch('todo/sort', {
            groupAuth: params.groupAuth,
            topicAuth: params.topicAuth,
            tab: params.tab,
            ids: items.map(el => el.id)
        }, {root: true});

        /*return store.dispatch('api', {
            ...params,
            action: 'user-task-sort',
        }, {root: true});*/
    },

};

export default actions;

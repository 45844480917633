import Config from '@/config';

const actions = {
    confirm(store, params) {
        return new Promise((resolve, reject)=>{
            store.rootState.ui.dialogConfirm.title = params?.title;
            store.rootState.ui.dialogConfirm.message = params?.message;
            store.rootState.ui.dialogConfirm.ok = params?.ok;
            store.rootState.ui.dialogConfirm.callback = (res)=>{
                return resolve(res);
            };
            store.rootState.ui.dialogConfirm.isVisible = true;
        });
    },
};

export default actions;

<template>
	<v-avatar :size="size" class="avatar"
			  :rounded="rounded"
			  :class="componentClass" color="grey lighten-4" :title="title">
		<v-img v-if="user.avatar" :src="user.avatar?.srcThumb" :lazy-src="user.avatar?.srcLazy" />
		<span v-else class="avatar__fi">{{fi}}</span>
	</v-avatar>
</template>

<script>
import * as userUtils from "@/utils/user";

export default {
	name: "avatar",
	props: {
		user: {},
		small: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({}),
	computed: {
		size() {
			if ( this.large ) return 32;
			if ( this.small ) return 18;
			return 24;
		},
		fi() {
			return userUtils.fi(this.user);
		},
		title() {
			return userUtils.nick(this.user);
		},
		componentClass() {
			const c = [];
			if ( this.small ) c.push("--small");
			return c?.length > 0 ? c : null;
		}
	}
}
</script>

<style lang="scss">
.avatar {
	//width: 28px;
	//height: 28px;
	overflow: hidden;
	text-transform: uppercase;

	/*&.--small {
		width: 20px;
		height: 20px;
	}*/

	&__fi {
		font-size: .55rem;
	}
}
</style>
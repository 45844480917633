import Config from '@/config';

const actions = {
    upload(store, params) {
        return store.dispatch('post', {
            action: 'file-create', params
        }, {root: true});
    },
};

export default actions;

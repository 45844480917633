import Vue from 'vue';
import {nf, nff, cf, price, phone} from './string';
import {date2dmyhms, date2hm, iso2Date, mysql2DDMMYYYY, date2dmyhm} from './date';

const filters = {
	nf,
	nff,
	cf,
	price,
	phone,
	date: (text)=>{
		return mysql2DDMMYYYY(text)
	},
	datetime: (text)=>{
		return date2dmyhms(iso2Date(text))
	},
	datehm: (text)=>{
		return date2dmyhm(iso2Date(text))
	},
	time: (text)=>{
		return date2hm(iso2Date(text))
	},
};

export default () => {
	for (let filterName in filters) {
		if (!filters.hasOwnProperty(filterName)) continue;
		Vue.filter(filterName, filters[filterName]);
	}
}
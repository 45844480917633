import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import draggable from './draggable';
import group from './group';
import msg from './msg';
import task from './task';
import topic from './topic';
import user from './user';
import workspace from './workspace';
import file from './file';
import transaction from './transaction';
import ui from './ui';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    task,
    msg,
    topic,
    user,
    workspace,
    group,
    draggable,
    file,
    transaction,
    ui,
  }
})

<template>
	<div class="draggable" :class="itemClass"
		 :draggable="enabled"
		 @dragstart="onDragStart" @dragend="onDragEnd"
		 @drop="onDrop" @dragenter="onDragOverMeStarted" @dragleave="onDragOverMeEnded" @dragover.prevent="onDragOver"
		 ref="el">
		<slot name="default"></slot>
		<!--		<div class="draggable__handle"></div>-->
	</div>
</template>

<script>
export default {
	name: "draggable",
	props: {
		zone: {
			type: String
		},
		acceptsZones: {
			type: Array
		},
		inline: {
			type: Boolean,
			default: false
		},
		data: {},
		enabled: {
			type: Boolean,
			default: true
		},
	},
	data: () => ({
		isDragging: false,
		dragOverCounter: 0,
		//isDropped: false,
		//dummy: null,
	}),
	computed: {
		itemClass() {
			const c = [];
			if (this.inline) c.push("--inline");
			if (this.isDragging) c.push("--dragging");
			if (this.dragOverCounter) c.push("--dragover");
			//if ( this.isDropped ) c.push("--dropped");
			return c;
		},
		element() {
			return {
				zone: this.zone,
				data: this.data
			}
		}
	},
	methods: {
		onDragStart(e) {
			//console.log("Drag started", e);
			this.isDragging = true;
			//this.startRect = this.createDummy();
			//document.addEventListener("mousemove", this.onMouseMove);
			this.$store.state.draggable.lastDragElement = this.element;
			this.$store.state.draggable.lastDropElement = null;
			e.dataTransfer.setData("application/ld+json", JSON.stringify(this.element));
			this.$emit("start");
		},
		onDragEnd(e) {
			//console.log("onDragEnd", e);
			this.isDragging = false;

			this.$emit("end", this.element, this.$store.state.draggable.lastDropElement);
		},
		onDrag(e) {
			//console.log("onDrag", e);
			/*if ( this.dummy && this.isDragging && e.x && e.y) {
				this.dummy.style.left = (e.x - (this.mouseStartX - this.startRect.left)) + "px";
				this.dummy.style.top = (e.y - (this.mouseStartY - this.startRect.top)) + "px";
			}*/
		},
		onDragOverMeStarted(e) {
			if ( !this.element?.data ) return;
			if ( !this.$store.state.draggable.lastDragElement ) return;
			//console.log("DRAG ENTER", this.acceptsZones, this.zone);
			//console.log("onDragEnter", this.zone, this.$store.state.draggable.lastDragElement);
			if ((!this.acceptsZones || this.acceptsZones.includes(this.$store.state.draggable.lastDragElement.zone))	// this is allowed drop zone
				//&& this.element.zone === this.$store.state.draggable.lastDragElement.zone
				&& this.element.data !== this.$store.state.draggable.lastDragElement.data	// dragged element is not me
			) this.dragOverCounter++;
			//e.preventDefault();
			/*if ( this.dummy && this.isDragging && e.x && e.y) {
				this.dummy.style.left = (e.x - (this.mouseStartX - this.startRect.left)) + "px";
				this.dummy.style.top = (e.y - (this.mouseStartY - this.startRect.top)) + "px";
			}*/
		},
		onDragOverMeEnded(e) {
			if ( !this.element?.data ) return;
			if ( !this.$store.state.draggable.lastDragElement ) return;
			//console.log("onDragLeave", this.data?.id);
			if (/*this.element.zone === this.$store.state.draggable.lastDragElement.zone
				&& */this.element.data !== this.$store.state.draggable.lastDragElement.data
				&& this.dragOverCounter) this.dragOverCounter--;
			//e.preventDefault();
			/*if ( this.dummy && this.isDragging && e.x && e.y) {
				this.dummy.style.left = (e.x - (this.mouseStartX - this.startRect.left)) + "px";
				this.dummy.style.top = (e.y - (this.mouseStartY - this.startRect.top)) + "px";
			}*/
		},
		onDragOver(e) {
			//console.log("onDragOver", e);
			//e.preventDefault();
		},
		onDrop(e) {
			//console.log("onDrop", e);
			if ((!this.acceptsZones || this.acceptsZones.includes(this.$store.state.draggable.lastDragElement.zone))	// this is allowed drop zone
				&& this.element.data !== this.$store.state.draggable.lastDragElement.data) {
				this.dragOverCounter = 0;
				let source = e.dataTransfer.getData("application/ld+json");
				if (source) source = JSON.parse(source);

				this.$store.state.draggable.lastDropElement = this.element;
				this.$emit("drop", source, this.element);
			}
		},
		createDummy() {
			const el = this.$refs['el'];
			const rect = el.getBoundingClientRect();
			//const x = rect.left;
			this.dummy = document.createElement("div");
			document.body.appendChild(this.dummy);
			this.dummy.classList.add("draggable__dummy");
			this.dummy.innerHTML = el.innerHTML;
			this.dummy.style.width = rect.width + "px";
			this.dummy.style.height = rect.height + "px";
			this.dummy.style.left = rect.left + "px";
			this.dummy.style.top = rect.top + "px";
			return rect;
		},
		deleteDummy() {
			if (this.dummy) {
				this.dummy.classList.add("--drop");
				setTimeout(() => {
					document.body.removeChild(this.dummy);
					this.dummy = null;
				}, 300);
			}
		}
	},
	mounted() {

	}
}
</script>

<style lang="scss">
.draggable {
	position: relative;
	@include transition();

	/*& > * {
		user-select: none;
	}*/

	&.--inline {
		display: inline-block;
	}

	&.--dragging {
		opacity: 0.25;
		//filter: blur(2px);

		/*.draggable__handle {
			opacity: 1;
			//transition-delay:1.5s;
		}*/
	}

	&.--dragover:not(.--dragging) {
		background-color: map-get($blue, "lighten-5");

		& > * {
			opacity: 0.25;
			//filter: blur(2px);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			//opacity: 1;
			border: 1px dotted map-get($blue, "lighten-1");
			//transition-delay:1.5s;
		}
	}

	&.--dropped {
		background-color: map-get($yellow, "lighten-1");
	}

	&:after {
		@include transition();
	}

	/*&__handle {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//border: 1px dotted map-get($blue, "lighten-1");
		box-shadow: 0 0 15px map-get($blue, "lighten-1");
		border-radius: 5px;
		pointer-events: none;
		@include transition();
		opacity: 0;
		!*i {
			display: block;
			width: 16px;
			height: 4px;
			border-left: 2px solid $black;
			border-right: 2px solid $black;
			margin: 4px 0;
		}*!
	}

	&__dummy {
		position: absolute;
		box-shadow: 0 0 15px map-get($blue, "lighten-1");
		pointer-events: none;
		z-index: $z-index__dnd;
		background: $white;
		//filter: blur(1px);

		&.--drop {
			@include transition-long();
			filter: blur(4px);
			opacity: 0;
		}
	}*/
}
</style>
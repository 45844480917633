import store from "@/store";
import striptags from "striptags";
import {mysql2Date} from "@/utils/date";

export const TAB_ACTUAL = "actual";
export const TAB_DELAYED = "delayed";
export const TAB_TEMPLATES = "templates";
export const TAB_DONE = "done";
export const TAB_NAMES = [TAB_ACTUAL, TAB_DELAYED, TAB_DONE, TAB_TEMPLATES];

export default {
    prepareSrc(file) {
        file.src = "/file/" + file.auth;
        file.srcThumb = "/file/thumb/" + file.auth;
        file.srcLazy = "/file/lazy/" + file.auth;
        return file;
    },
    iconOf(file) {
        console.log("File", file.mime);
        if (this.isPDF(file)) return "mdi-file-pdf-box";
        else if (this.isJPG(file)) return "mdi-file-jpg-box";
        else if (this.isPNG(file)) return "mdi-file-png-box";
        else if (this.isGIF(file)) return "mdi-file-gif-box";
        else if (this.isXLS(file)) return "mdi-google-spreadsheet";
        else if (this.isArchive(file)) return "mdi-zip-box";
        return "mdi-text-box";
    },
    isPDF(file) {
        return file.mime?.match(/application\/pdf/i);
    },
    isJPG(file) {
        return file.mime?.match(/image\/jpeg/i);
    },
    isPNG(file) {
        return file.mime?.match(/image\/png/i);
    },
    isGIF(file) {
        return file.mime?.match(/image\/gif/i);
    },
    isArchive(file) {
        return file.mime?.match(/application\/zip/i)
            || file.mime?.match(/application\/vnd\.rar/i);
    },
    isImage(file) {
        return this.isPNG(file) || this.isJPG(file) || this.isGIF(file);
    },
    isXLS(file) {
        return file.mime?.match(/application\/vnd.ms-excel/i)
            || file.mime?.match(/application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/i);
    },
    preloadContent(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.loadend = () => {
                //console.log("Upload loaded?");
            }
            reader.onload = (e) => {
                const content = e.target.result;
                resolve({
                    name: file.name,
                    size: file.size,
                    content,
                    mime: content.split(";")[0].split(":")[1],
                });
            }
            reader.readAsDataURL(file);
            //reader.readAsArrayBuffer(this.uploadForm.files);
            //console.log("FILE", this.uploadForm);
        });
    },
    async upload(files, action, params) {
        console.log("uploadFiles", files?.length);
        if (!files?.length) return;
        params = params || {};

        // read uploaded files
        const uploads = await new Promise(async (resolve, reject) => {
                const ps = [];
                [...files].forEach(file => ps.push(this.preloadContent(file)));
                await Promise.all(ps).then(values => {
                    resolve(values);
                });
        });
        if (!uploads) return;

        return store.dispatch(action, {
            ...params,
            uploads
        });

    },
}

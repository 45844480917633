<template>
	<lord-icon v-if="isVisible" class="anim"
			   :trigger="trigger"
			   :src="src"
			   :colors="colors"/>
</template>

<script>
import {Element} from 'lord-icon-element/element';
import {defineElement} from 'lord-icon-element';

class InScreen {
	element;
	targetElement;
	player;
	observer;

	constructor(element, targetElement, player) {
		this.element = element;
		this.targetElement = targetElement;
		this.player = player;

		const intersectionCallback = (entries, observer) => {
			entries.forEach((entry) => {
				if (!this.player.isPlaying) {
					setTimeout(() => {
						this.player.playFromBegining();
					}, 50);
				}
			});
		};

		this.observer = new IntersectionObserver(intersectionCallback, {
			threshold: 0.75,
		});
	}

	onReady() {
		this.observer.observe(this.targetElement);
	}

	onDisconnected() {
		this.observer.disconnect();
	}
}

Element.defineTrigger('in-screen', InScreen);

export default {
	name: "anim",
	props: {
		name: {
			type: String
		},
		trigger: {
			type: String,
			default: "in-screen"
		},
		delay: {
			type: Number,
			default: 0
		},
	},
	data: () => ({
		isVisible: false,
	}),
	computed: {
		/*trigger() {
			// todo accept pros
			return "in-screen";
		},*/
		src() {
			return "/lottie/" + this.name + ".json";
		},
		colors() {
			//return "primary:#fdd394,secondary:#03a9f4";
			//return "primary:#F8ED62";
			return "primary:#F8ED62";
		}
	},
	mounted() {
		if (this.delay) {
			setTimeout(() => this.isVisible = true, this.delay);
		} else {
			this.isVisible = true;
		}
	}
}
</script>

<style lang="scss">
.anim {
	width: 100%;
	height: 100%;

	animation: anim-fade-in .5s;
	animation-delay: .150s;
	opacity: 0;
	animation-fill-mode: forwards;
}

@keyframes anim-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>
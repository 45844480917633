<template>
	<v-dialog
		v-model="params.isVisible"
		max-width="500">
		<v-card>
			<v-card-title>
				{{ params?.title || 'Уверены?' }}
			</v-card-title>

			<v-card-text>
				Пожалуйста, подтвердите удаление.
			</v-card-text>

			<v-divider/>

			<v-card-actions>
				<v-btn
					text
					@click="params.isVisible = false">
					Отмена
				</v-btn>
				<v-spacer/>
				<v-btn
					color="primary"
					text
					:loading="isLoading"
					@click="onConfirmed">
					Да!
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "dialog-delete",
	components: {},
	props: {
		params: {}
	},
	data() {
		return {
			isLoading: false,
		}
	},
	methods: {
		async onConfirmed() {
			if (this.params.callback) {
				this.isLoading = true;
				await this.params.callback(this.params);
			}
			this.isLoading = false;
			this.params.isVisible = false;
		}
	}
}
</script>

<style lang="scss">
</style>
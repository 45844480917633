<template>
	<v-icon class="loader" :class="componentClass" color="grey">mdi-dots-circle</v-icon>
</template>

<script>
export default {
	name: "loader",
	props: {
		small: {type: Boolean, default: false},
	},
	data: () => ({
	}),
	computed: {
		componentClass() {
			const c = [];
			if ( this.small ) c.push("--small");
			return c?.length ? c : null;
		}
	}
}
</script>

<style lang="scss">
.loader {
	animation: loader-animation 1s infinite linear;
	/*.v-progress-circular__overlay {
		transition: none !important;
	}*/
	&.--small {
		width: 16px;
		height: 16px;
		font-size: 16px;
	}
	&.--normal {
		width: 24px;
		height: 24px;
		font-size: 24px;
	}
}

@keyframes loader-animation {
	from {
		transform: rotate(0)
	}
	to {
		transform: rotate(360deg)
	}
}
</style>